/* InvoiceStyles.css */
.table-hover tbody tr:hover td, 
.table-hover tbody tr:hover th {
    background-color: #f5f5f5; /* Light grey background on hover */
}
.cursor-pointer {
    cursor: pointer;
}
.width60{    width: 60px;}
.tableItemName{width: 130px;}
.actionButton{    --bs-btn-padding-x: 20;
    --bs-btn-padding-y: 20;
    padding:0px 6px 1px 6px;}
.actionButtonSpace{margin-left:10px;}
.priceChange{font-size:10px;}
.buttonSize{width:22px;--bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;}
.buttonSpace{width:22px;margin-left:10px;--bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;}
.svg-inline--fa{height: .8em;}
/* Ensures hover effect does not change the text color */
.table-hover tbody tr:hover td a, 
.table-hover tbody tr:hover th a {
    color: inherit; /* Keeps the text color the same as it normally is */
    text-decoration: none; /* Removes any underline from links */
}
.item-details {
    display: flex;        /* Enables flexbox layout */
    flex-wrap: wrap;      /* Allows items to wrap onto the next line if not enough space */
    gap: 20px;            /* Adds space between the flex items */
    justify-content: flex-start; /* Aligns items to the start of the flex container */
}

.item-details p {
    margin: 0; /* Removes default paragraph margins for tighter control */
    white-space: nowrap; /* Prevents the text inside paragraphs from wrapping */
}
.list-group-item:hover {
    cursor: pointer; /* Changes cursor to a pointer on hover */
}

.clickable-item {
    cursor: pointer;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.clickable-item:hover {
    color: #007BFF; /* Change color to bootstrap's primary blue on hover */
}
.table-hover tbody tr:hover {
    cursor: pointer;
    background-color: #f5f5f5; /* Light grey background on hover */
}
.invoiceEdit{width:60px;}