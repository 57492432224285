/* WelcomePage.css */
.welcome-container {
    
    text-align: center;
    color: #333;
    font-family: 'Arial', sans-serif;
    padding-top: 20px; /* Slight gap from the top */
}
.logo-img {
    width: 40px;
    vertical-align: middle;
    margin-right: 10px;
}

.text-background{background-color: black; border-radius: 5px 5px 0px 0px;padding: 20px; text-align:center;}
.welcome-banner {
    background: url('chef.png') no-repeat center center/cover; /* Add your image path here */
    height: 338px;
    max-width: 822px; /* Maximum width */
    margin: 0 auto; /* Centering the banner */
    display: flex;
    align-items: center;
    position: relative;
   
 
}
.intro-text{
    margin: 0 auto; /* Centering the banner */
    display: flex;
    max-width: 822px; /* Maximum width */
    position: relative;
    color: #ffffff;
    background-color: #000;
    font-family: 'Arial', sans-serif;
    text-align: left;
    padding: 20px; /* Slight gap from the top */
    border-radius: 0px 0px 5px 5px;
}
.welcome-text {
    text-align: left;
    margin: 0 auto; /* Centering the banner */
    flex-grow: 1;
    color: #FFF;
    max-width:  822px; /* Maximum width */
}


.signup-box {
    width: 250px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8); /* White with slight opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-left: 20px; /* Margin on the left side inside the banner */
}

.welcome-header h1 {
    font-size: 2.5em;
    color: #2a9d8f;
}

.welcome-title {
    display: inline; /* Align title inline with the image */
    vertical-align: middle;
}

.features {
    margin: 20px auto;
    padding: 0 15px;
    max-width: 800px;
    font-size: 1.1em;
    line-height: 1.6;
    text-align: justify;
}

.screenshot-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the items in the row */
    margin: 20px auto; /* Centers the gallery and adds vertical space */
    max-width: 920px; /* Maximum width of the gallery */
}

.screenshot-gallery img {
    max-width: 400px;  /* Set width to 400px */
    height: auto;  /* Set height to auto to maintain aspect ratio */
    margin: 10px;  /* Adds space around each image */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px; /* Optional: adds rounded corners to images */
    flex: 0 0 48%; /* Flex basis set to 48% to ensure only two images per row with some space between */
}

